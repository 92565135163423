var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _c("h1", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.query.type === "edit"
                    ? "编辑角色"
                    : _vm.query.type === "show"
                    ? "查看角色"
                    : "新增角色"
                ) +
                " "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "roleName" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      maxlength: "10",
                      "show-word-limit": "",
                      placeholder: "请输入角色名称",
                    },
                    model: {
                      value: _vm.ruleForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "roleName", $$v)
                      },
                      expression: "ruleForm.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色说明", prop: "roleDesc" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      maxlength: "50",
                      "show-word-limit": "",
                      type: "textarea",
                      rows: 3,
                      placeholder: "请输入角色说明",
                    },
                    model: {
                      value: _vm.ruleForm.roleDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "roleDesc", $$v)
                      },
                      expression: "ruleForm.roleDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限", prop: "navigationList" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      props: _vm.props,
                      data: _vm.options,
                      "node-key": "navigationId",
                      "show-checkbox": "",
                    },
                    on: { "check-change": _vm.changePermission },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }