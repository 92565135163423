<template>
  <div>
    <page-content>
      <h1 style="margin-bottom: 30px">
        {{ query.type==='edit'?'编辑角色':(
          query.type==='show'?'查看角色':'新增角色'
        ) }}
      </h1>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="角色名称"
          prop="roleName"
        >
          <el-input
            v-model="ruleForm.roleName"
            class="width360"
            size="mini"
            maxlength="10"
            show-word-limit
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色说明"
          prop="roleDesc"
        >
          <el-input
            v-model="ruleForm.roleDesc"
            class="width360"
            size="mini"
            maxlength="50"
            show-word-limit
            type="textarea"
            :rows="3"
            placeholder="请输入角色说明"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="权限"
          prop="navigationList"
        >
          <!-- <el-cascader
              @change="changePermission"
              :options="options"
              :props="props"
              collapse-tags
              clearable>
            </el-cascader> -->
          <el-tree
            ref="tree"
            :props="props"
            :data="options"
            node-key="navigationId"
            show-checkbox
            @check-change="changePermission"
          >
          </el-tree>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </page-content>
  </div>
</template>

<script>
import { addRole, getRoleView, editRole } from '@/service/roleService'

export default {
  name: 'ExtractDetail',
  data() {
    return {
      query: this.$route.query,
      ruleForm: {
        roleName: '',
        roleDesc: '',
        navigationList: ''
      },
      couponList: [], // 优惠券列表
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'change' }
        ],
        navigationList: [
          { required: true, message: '请选择权限', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择邀请条件', trigger: 'change' }
        ]
      },
      props: { multiple: true, label: 'name', value: 'navigationId', children: 'children' },
      options: [],
      loading: false
    }
  },
  mounted() {
    this.getPerssionList()
    if (this.query.type === 'edit') {
      this.getRoleInfo()
    }
  },
  methods: {
    getPerssionList() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$api.navigation.list
      }).then((res) => {
        if (res.code === 0) {
          this.options = res.data
          console.log(this.options, '=====')
          this.options.forEach(item => {
            if (item.children.length > 0) {
              item.children.forEach(ele => {
                if (ele.children.length === 0) {
                  delete ele.children
                }
              })
            } else if (item.children.length === 0) {
              delete item.children
            }
          })
        }
        this.loading = false
      })
    },
    submitForm(formName) {
      let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确定保存角色信息吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let data = {
              roleName: this.ruleForm.roleName,
              roleDesc: this.ruleForm.roleDesc,
              navigationList: this.ruleForm.navigationList,
              roleId: this.$route.query.id
            }
            if (this.query.type === 'add') {
              addRole(data).then(res => {
                if (res.code === 0) {
                  that.$message.success('保存成功')
                  that.$router.go(-1)
                }
              })
            } else {
              editRole(data).then(res => {
                if (res.code === 0) {
                  that.$message.success('保存成功')
                  that.$router.go(-1)
                }
              })
            }
          }).catch((error) => {

          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 权限选择
    changePermission(data) {
      this.ruleForm.navigationList = this.$refs.tree.getCheckedKeys()
    },
    // 获取角色信息
    getRoleInfo() {
      getRoleView({ roleId: this.query.id }).then(res => {
        if (res.code === 0) {
          this.ruleForm = {
            roleName: res.data.roleName,
            roleDesc: res.data.roleDesc,
            navigationList: res.data.navigationList
          }
          this.$refs.tree.setCheckedKeys(res.data.navigationList)
        }
      })
    }
  }
}
</script>
<style scoped>
.width360 {
  width: 360px;
}
</style>
